import { v4 } from "uuid";
import cookieUtil from "./cookieUtils";
import { VISITOR_UUID, CURRENCY, LANG, COUNTRY, TIMEZONE } from "@/utils/const";

/**
 * 从cookie中获取用户/访客uuid
 */
export function getUserUUIDFromCookie() {
  let visitorUuid = cookieUtil.getCookie(VISITOR_UUID);
  if (!visitorUuid) {
    visitorUuid = v4();
    cookieUtil.setCookie(VISITOR_UUID, visitorUuid);
  }
  return visitorUuid;
}
