import { useState, useEffect } from "react";
import style from "./style.module.scss";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import JDIPrice from "@/common-components-src/js/price";
import AddCartIcon from "@/common-components-src/assets/icons/AddCart.svg";
import Star from "@/common-components-src/assets/icons/Star.svg";
import DefaultImg from "@/common-components-src/assets/icons/defaultImg.svg";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddCart } from "@/common-components-src/api/cart/cart.api";
import { formatStyle, imgTipsStyle, comparePrice, getExptag } from "../../common/utils";
import { formatProductLink } from "@/common-components-src/js/utils/formatProductLink";
import type { productData_2 } from "../../index.interface";
import UKToast from "@/common-components-src/components/UKToast/index";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import { getUserUUIDFromCookie } from "@/utils/userUtils";
import ProTypeModal from "@/common-components-src/components/UKProductSelect";
import Countdown from "@/common-components-src/js/countdown/index";
import Image from "../image/index";
import { useTranslation } from "@/i18n/client";

interface PropsType {
  productData: productData_2;
}

export default function ProductCard_2({ productData }: PropsType) {
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);
  const [isShowProdectModal, setIsShowProdectModal] = useState(false); // 是否显示选择商品类型弹窗
  const [isLoaded, setIsLoaded] = useState(false); // 选择商品是否加载完成
  const [dialogStyle, setDialogStyle] = useState({
    background: "transparent",
    boxShadow: "none",
  });
  useEffect(() => {
    if (isLoaded) {
      setDialogStyle({
        background: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      });
    } else {
      setDialogStyle({
        background: "transparent",
        boxShadow: "none",
      });
    }
  }, [isLoaded]);

  //点击商卡事件
  const handleProductClick = (e: any) => {
    e.stopPropagation();
    if (trackingPointParams && trackingPointParams.productClickPoint) {
      trackingPointParams.productClickPoint();
    }
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickProduct && typeof onClickProduct === "function") {
      onClickProduct(productData);
    }
  };

  //点击购物车事件
  const handleCartClick = (e: any, productData: productData_2) => {
    e.preventDefault();
    e.stopPropagation();
    if (trackingPointParams && trackingPointParams.cartClickPoint) {
      trackingPointParams.cartClickPoint();
    }
    // 检查 onClickCart 是否存在并且是一个函数
    if (onClickCart && typeof onClickCart === "function") {
      onClickCart(productData);
    } else {
      setIsShowProdectModal(true);
      setIsLoaded(false);
      setDialogStyle({
        background: "transparent",
        boxShadow: "none",
      });
    }
  };
  const gotoCart = (targetSkuId: string) => {
    const params = {
      cartuuid: getUserUUIDFromCookie(),
      verticalTag: "cn_ybxt_b2c",
      userActionId: "addCart",
      operations: {
        products: [{ itemType: itemType, skuId: targetSkuId ? targetSkuId : productData.skuId, num: "1" }],
      },
    };
    addCart(params);
  };
  /**
   * 加车
   * https://cf.jd.com/pages/viewpage.action?pageId=605887939
   */
  const addCart = async (params: any) => {
    const res = await AddCart(params);
    if (res?.code === "200") {
      if (trackingPointParams && trackingPointParams.addCartPoint) {
        trackingPointParams.addCartPoint();
      }
      UKToast.show({
        content: t('common.message.AddedToCart'), // 弹窗内容
        icon: "success", // 可选：success, fail, loading, warn, info，影响图标
      });
      if (window && (window as any).JDIheader) (window as any).JDIheader.initCartNum();
      if (onAddCartSuccess && typeof onAddCartSuccess === "function") onAddCartSuccess(productData);
    } else {
      UKToast.show({
        content: t('common.message.AddedToCartFailedPleaseTryAgain'), // 弹窗内容
        icon: "error", // 可选：success, fail, loading, warn, info，影响图标
      });
    }
  };

  let {
    width,
    height,
    containerStyle,
    skuName,
    skuNameStyle,
    skuImgKeyParams,
    skuImg,
    skuImgStyle,
    skuId,
    realPrice,
    realPriceJDIPriceParams,
    originalPrice,
    originalPriceJDIPriceParams,
    currency,
    imgTips,
    shouldShowCart,
    itemType = 1,
    onClickCart,
    onAddCartSuccess,
    jumpTarget,
    onClickProduct,
    saleTipsDom,
    productLabelDom,
    titleRightDom,
    score,
    saleNum,
    scoreStyle,
    marketingStyle_1,
    marketingArray_1 = [],
    marketingStyle_2,
    marketingArray_2 = [],
    shouldShowMoreModel,
    moreModels,
    cdnDomains,
    moreModelsStyle,
    isOutOfStock,
    isRemoved,
    promotions,
    priceStyle,
    saleInfo,
    saleInfoStyle,
    trackingPointParams,
  } = productData;

  containerStyle = Object.assign({}, containerStyle, {
    width: width && formatStyle(width),
    height: height && formatStyle(height),
  });

  let group_0: any = productLabelDom ? [productLabelDom] : []; //type=0是标题前打标
  let group_1: any = [...marketingArray_2]; //type=1是价格下面所有标签
  let group_2: any = saleTipsDom ? [saleTipsDom] : []; //type=2是划线价格后打标
  let group_3: any = [...marketingArray_1]; //srType=3是标题下，价格上标签（优先级最高）
  let group_4: any = []; //评分后标签，目前展示内容是
  function expandShowStyle(obj: any) {
    const showStyleObj = JSON.parse(obj.showStyle);
    return { ...obj, ...showStyleObj };
  }
  function handleImageError() {
    setImageError(true);
  }
  const getPromotions = () => {
    promotions?.map((promotion) => {
      promotion = expandShowStyle(promotion);
      if (promotion.endTime && promotion.srType === 3) {
        const dom = (
          <span>
            <span dangerouslySetInnerHTML={{ __html: promotion.richText }} key={promotion.text} style={{ marginRight: "3px" }}></span>
            <Countdown countdown={promotion.endTime} format={promotion?.format}></Countdown>
          </span>
        );
        group_3.push(dom);
      } else if (promotion.srType === 3) {
        const dom = <span dangerouslySetInnerHTML={{ __html: promotion.richText }} key={promotion.text}></span>;
        group_3.push(dom);
      } else if (promotion.type === 0) {
        const dom = <span dangerouslySetInnerHTML={{ __html: promotion.richText }} key={promotion.text}></span>;
        group_0.push(dom);
      } else if (promotion.type === 1) {
        const dom = <span dangerouslySetInnerHTML={{ __html: promotion.richText }} key={promotion.text}></span>;
        group_1.push(dom);
      } else if (promotion.type === 2) {
        const dom = <span dangerouslySetInnerHTML={{ __html: promotion.richText }} key={promotion.text}></span>;
        group_2.push(dom);
      } else if (promotion.type === 4) {
        const dom = <span dangerouslySetInnerHTML={{ __html: promotion.richText }} key={promotion.text}></span>;
        group_4.push(dom);
      }
    });
  };
  getPromotions();

  const handleClose = () => {
    setIsShowProdectModal(false);
  };

  return (
    <div
      className={style.UK_product_card}
      style={containerStyle}
      key={skuId}
      exptag={getExptag("exptag", trackingPointParams, "productExpConfig")}
      data-exptag-config={getExptag("config", trackingPointParams, "productExpConfig")}
      data-exptag-json-param={getExptag("json-param", trackingPointParams, "productExpConfig")}
      data-exptag-event-param={getExptag("event-param", trackingPointParams, "productExpConfig")}
    >
      <a href={formatProductLink(skuId, skuName)} target={jumpTarget} onClick={(e) => handleProductClick(e)}>
        {/* 商品图片 */}
        <div className={style.UK_image_wrapper}>
          {imageError ? (
            <DefaultImg className={`${style.UK_skuImg} ${isOutOfStock || isRemoved ? style.op50 : ""}`} />
          ) : (
            <img
              className={`${style.UK_skuImg} ${isOutOfStock || isRemoved ? style.op50 : ""}`}
              src={
                skuImgKeyParams
                  ? imageUtil.concatImgUrlWithoutForceHttps(
                    skuImgKeyParams.imgUrlKey,
                    skuImgKeyParams?.width ? skuImgKeyParams.width * 2 : 480,
                    skuImgKeyParams?.height ? skuImgKeyParams.height * 2 : 480,
                    skuImgKeyParams?.quality || 100,
                    skuImgKeyParams?.domains || cdnDomains,
                  )
                  : skuImg || ""
              }
              style={skuImgStyle}
              alt={skuName}
              onError={handleImageError}
            />
          )}

          {/* 商品图片背景及hover遮罩 */}
          <div className={style.UK_mask}></div>
          {/* 是否展示无货状态 */}
          {isRemoved ? (
            <div className={style.UK_outOfStock}>
              <div className={style.UK_outOfStock_text}>{t("common.productStatus.removed")}</div>
            </div>
          ) : isOutOfStock ? (
            <div className={style.UK_outOfStock}>
              <div className={style.UK_outOfStock_text}>{t("common.productStatus.outOfStock")}</div>
            </div>
          ) : null}
          {imgTips && imgTips.tipDom ? (
            <span className={style.UK_imgTips} style={imgTipsStyle(imgTips.position)}>
              {imgTips.tipDom}
            </span>
          ) : null}
        </div>
      </a>
      {/* 多规格展示 */}
      {shouldShowMoreModel && moreModels && moreModels.length > 0 && (
        <div className={style.UK_moreModel_wrapper} style={moreModelsStyle}>
          {moreModels.map((item, index) => {
            if (index < 4) {
              return (
                <a className={style.UK_moreModel} href={formatProductLink(item.wareId)} key={item.wareId}>
                  <Image src={imageUtil.concatImgUrlWithoutForceHttps(item.imageUrl, 40, 40, 100, cdnDomains)} />
                  <div className={style.UK_mask}></div>
                </a>
              );
            } else if (index === 4) {
              return (
                <a className={style.UK_moreModel} key={item.wareId} href={formatProductLink(moreModels.length > 5 ? skuId : item.wareId)}>
                  {moreModels.length > 5 ? (
                    <span className={style.UK_moreModelNum}>+{moreModels.length < 103 ? moreModels.length - 4 : 99}</span>
                  ) : (
                    <Image src={imageUtil.concatImgUrlWithoutForceHttps(item.imageUrl, 40, 40, 100, cdnDomains)} />
                  )}
                  <div className={style.UK_mask}></div>
                </a>
              );
            }
          })}
        </div>
      )}
      <a href={formatProductLink(skuId, skuName)} target={jumpTarget} onClick={(e) => handleProductClick(e)}>
        {/* 商品名称 */}
        <div className={style.UK_name_wrapper} style={skuNameStyle}>
          <div className={style.UK_name_box}>
            {/* 商品标签 */}
            {group_0.length > 0 ? <span className={style.UK_productLabelDom}>{group_0[0]}</span> : null}
            <span className={style.UK_name}>{skuName}</span>
          </div>
          {titleRightDom}
        </div>
        {saleInfo && (
          <div className={style.UK_saleInfo} style={saleInfoStyle}>
            {saleInfo}
          </div>
        )}
        {/* 商品名称下评分 */}
        {score || saleNum ? (
          <div className={style.UK_score} style={scoreStyle}>
            {score ? (
              <span className={style.UK_star}>
                <Star className={style.UK_starIcon}></Star>
                {score}
              </span>
            ) : null}
            {group_4.length > 0 ? <span className={style.UK_saleNum}>{group_4[0]}</span> : null}
            {/* {saleNum ? <span className={style.UK_saleNum}>{saleNum}</span> : null} */}
          </div>
        ) : null}

        {/* 商品名称下营销标签 */}
        {group_3.length > 0
          ? group_3.map((item, i) => {
            return (
              <div className={style.UK_activityRow} key={i} style={marketingStyle_1}>
                {item}
              </div>
            );
          })
          : null}

        {/* 商品价格+ 营销标签 + 购物车 */}
        <div className={`${style.UK_price_wrapper} ${originalPrice && comparePrice(originalPrice, realPrice) ? style.showOriginalPrice : ""}`}>
          <div className={style.UK_left} style={priceStyle}>
            {/* 优惠价格 */}
            <div className={style.UK_realPrice}>
              <JDIPrice {...realPriceJDIPriceParams} symbol={currency} price={realPrice} uiType={1} containerStyle={{ marginRight: "8px" }} />
            </div>
            {/* 划线价 */}
            {originalPrice && comparePrice(originalPrice, realPrice) && (
              <div className={style.UK_originalPrice}>
                <JDIPrice
                  {...originalPriceJDIPriceParams}
                  symbol={currency}
                  containerStyle={{ lineHeight: "16px" }}
                  price={originalPrice}
                  uiType={2}
                  lineThrough
                />
                {group_2.length > 0 ? <span className={style.UK_saleTipsDom}>{group_2[0]}</span> : null}
              </div>
            )}
          </div>
          {shouldShowCart && !isRemoved ? (
            <div
              className={style.UK_right}
              exptag={getExptag("exptag", trackingPointParams, "cartExpConfig")}
              data-exptag-config={getExptag("config", trackingPointParams, "cartExpConfig")}
              data-exptag-json-param={getExptag("json-param", trackingPointParams, "cartExpConfig")}
              data-exptag-event-param={getExptag("event-param", trackingPointParams, "cartExpConfig")}
            >
              <AddCartIcon className={style.UK_cart} onClick={(e: any) => handleCartClick(e, productData)}></AddCartIcon>
            </div>
          ) : null}
        </div>

        {/* 底部营销标签 */}
        {group_1.length > 0
          ? group_1.map((item, i) => {
            return (
              <div className={style.UK_activityRow} key={i} style={marketingStyle_2}>
                {item}
              </div>
            );
          })
          : null}
      </a>
      <UKDialog
        open={isShowProdectModal}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "720px", // 设置自定义宽度
            maxWidth: "none", // 取消最大宽度限制
            maxHeight: "640px",
            minHeight: "334px",
            ...dialogStyle,
          },
        }}
      >
        {isLoaded && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent>
          <ProTypeModal skuId={skuId} handleClose={handleClose} setIsLoaded={setIsLoaded} updateItemData={gotoCart} />
        </DialogContent>
      </UKDialog>
    </div>
  );
}
