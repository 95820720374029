"use client";

import i18next, { FlatNamespace, KeyPrefix } from "i18next";
import { initReactI18next, useTranslation as useTranslationOrg, UseTranslationOptions, UseTranslationResponse, FallbackNs } from "react-i18next";
import en_GB from "./locales/en_GB.json";
import zh_CN from "./locales/zh_CN.json";
import cookieUtils from "@/utils/cookieUtils";

// on client side the normal singleton is ok
i18next
  .use(initReactI18next)
  // .use(LanguageDetector)
  // .use(resourcesToBackend((language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)))
  // .use(LocizeBackend) // locize backend could be used on client side, but prefer to keep it in sync with server side
  .init({
    lng: "en_GB", // let detect the language on client side
    supportedLngs: ["en", "zh", "dev", "en_GB", "zh_CN"],
    debug: true,
    preload: ["en", "zh", "dev", "en_GB", "zh_CN"],
    resources: {
      en_GB: {
        translation: en_GB,
      },
      zh_CN: {
        translation: zh_CN,
      },
    },
  });

export function useTranslation<Ns extends FlatNamespace, KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined>(
  lng?: string,
  ns?: Ns,
  options?: UseTranslationOptions<KPrefix>,
): UseTranslationResponse<FallbackNs<Ns>, KPrefix> {
  // -------------- useTranslation -------------------- //
  const ret = useTranslationOrg(ns, options);
  const { i18n } = ret;
  const i18nLang = i18n.language;
  const _lang = cookieUtils.getCookie("_lang");

  if (i18nLang !== _lang) {
    i18n.changeLanguage(_lang);
  }

  return ret;
}
